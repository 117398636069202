import { Button } from '@repo/ui/components/Button.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { Spacer } from '@repo/ui/components/Spacer.js'
import { useMemo, useState } from 'react'

type BarData = {
  value: number
  label: string
}

type Props<T extends BarData> = {
  data: T[]
  maxRows?: number
  formatValue?: (value: number) => any
  renderLabel?: (row: T) => React.ReactNode
}

export const HorizontalBarChart = <T extends BarData>({
  data,
  maxRows = 8,
  formatValue = (value: number) => value,
  renderLabel = (row: T) => row.label
}: Props<T>) => {
  const [showAll, setShowAll] = useState(false)

  const visibleRows = showAll ? data : data.slice(0, maxRows)

  const maxValue = useMemo(
    () =>
      data.reduce((acc, cur) => {
        return cur.value > acc ? cur.value : acc
      }, 0),
    [data]
  )

  return (
    <>
      <div className="space-y-2 text-sm font-medium">
        {visibleRows.map((row) => {
          const width = (row.value / maxValue) * 100
          return (
            <div
              key={row.label}
              className="flex items-center justify-between gap-8"
            >
              <div className="w-32 shrink-0 truncate">{renderLabel(row)}</div>
              <div className="h-6 w-full">
                <div
                  className="h-full rounded-r-md bg-brand-500"
                  style={{ width: `${width}%` }}
                />
              </div>
              <div className="shrink-0 text-right">
                {formatValue(row.value)}
              </div>
            </div>
          )
        })}
      </div>

      {visibleRows.length !== data.length || showAll ? (
        <>
          <Spacer size="sm" />
          <Button
            onClick={() => setShowAll(!showAll)}
            size="xs"
            variant="outline"
          >
            <Icon name={showAll ? 'chevron-up' : 'chevron-down'} />
            {showAll ? 'Show less' : 'Show more'}
          </Button>
        </>
      ) : null}
    </>
  )
}
